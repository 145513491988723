import { createWebHistory, createRouter } from 'vue-router'

import HomeViews from '@/views/HomeViews.vue';
import PageNotFound from '@/views/PageNotFoundViews.vue';

const routes = [
	{ path: '/', name: 'home', component: HomeViews },
	{ path: '/:pathMatch(.*)*', name: '404', component: PageNotFound },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
});

export default router