<template>
	<Header></Header>
	<Main></Main>
	<Footer></Footer>
</template>

<script>
import Header from './components/Header.vue';
import Main from './components/Main.vue';
import Footer from './components/Footer.vue';

export default {
	components: {
		Header,
		Main,
		Footer
	},
	beforeCreate() {
		const lng = this.$store.getters.languages.find(i => i.name == 'Eng') ?? this.$store.getters.languages[0];
		this.$store.commit('setSelectLang', lng);
		this.$store.commit('setIsMobile', !!(screen.width <= 1023 || !!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ) );
	},
	beforeMount() {
		window.onresize = () => {
			this.$store.commit('setIsMobile', !!(window.innerWidth <= 1023  || !!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ) );
		}
	},
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body, div, span, applet, object, iframe,
pre, a, abbr, acronym, address, big, del,
img, ins, strike, tt, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, 
thead, tr, th, td, article, aside, canvas, 
details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby
section, summary, time, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-family: 'Roboto', sans-serif;
	font-size: 100%;
	vertical-align: baseline;
}
/* make sure to set some focus styles for accessibility */
:focus {
	outline: 0;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	display: flex;
	flex-direction: column;
	background: #f8f8f8;
	color: #222;
	line-height: 1;
}
.body-dark {
	filter: invert(1) hue-rotate(180deg) contrast(0.8);
}

:root {
	/* --- first color --- */
	--color-blue1: #407bff;
	--color-blue2: #3a6fe6;
	--color-blue3: #33569b;
	--color-blue4: #6695ff;
	--color-blue5: #85aaff;
	--color-blue6: #89a9f0;

	--color-black1: #263238;

	--color-silver1: #ebebeb;
	--color-silver2: #b8bdc8;
	/* --- --- */

	--color-orange-kz: #FF9C06;
	--color-silver-kz: #535353;
	--color-blue-kz: #4EAEC4;
	
}
ol,
ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration: inherit;
	color: inherit;
	cursor: pointer;
}
p:only-child {
	margin-block-start: 0;
	margin-block-end: 0;
}
img {
	-ms-interpolation-mode:bicubic; 
}
input, button, textarea {
	outline: none;
	cursor: pointer;
	font-family: 'Nunito','Raleway','Mont','Mail Sans','Open Sans','Montserrat','Redlight';
	-webkit-appearance: none;
}
del {
	text-decoration-color: #ff5a5a;
}

/* data-title (custom title attribute) */
*[data-title]:hover::after {
	content: attr(data-title);
		position: absolute;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0.03em;
		font-family: 'Nunito';
		color: #333;
		background: #fff;
		padding: 10px;
		border-radius: 5px;
	opacity: 0;
	animation: fadeIn 200ms linear 600ms both;
		box-shadow: 0px 4px 11px #00000021, 0px 1px 6px #0000001c;
	z-index: 100000;
}
*[data-title]:not(:hover)::after {
	animation: fadeOut 200ms linear both;
}

@keyframes fadeIn {
	0% {
		opacity: 0;	
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;	
	}
	100% {
		opacity: 0;
	}
}

.fade-list-move,
.fade-list-enter-active,
.fade-list-leave-active {
		transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) ;
}
/* 2. declare enter from and leave to state */
.fade-list-enter-from,
.fade-list-leave-to {
	opacity: 0;
	transform: translate3d(0, 0, 0) scaleY(0) translate(30px, 0);
}
/* 3. ensure leaving items are taken out of layout flow so that moving
			animations can be calculated correctly. */
.fade-list-leave-active {
	position: absolute;
}


.popup-anim-move,
.popup-anim-enter-active,
.popup-anim-leave-active {
		transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
/* 2. declare enter from and leave to state */
.popup-anim-enter-from,
.popup-anim-leave-to {
	opacity: 0;
	transform: translate3d(0px, 0px, 0px) scale(1.3);
}
/* 3. ensure leaving items are taken out of layout flow so that moving
			animations can be calculated correctly. */
.popup-anim-leave-active {
	position: absolute;
}


input {
	font-family: 'Nunito';
	font-weight: 500;
	letter-spacing: 0.03em;
	font-size: 1rem;
	border: 1px solid #ddd;
	padding: 0.3125rem;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	border-radius: 1.25rem;
	min-width: 12.5rem;
}



@media all and (min-width: 1600px) and (max-width: 1700px) {
    html,body {
        font-size: 14px!important;
    }
}
@media all and (min-width: 1440px) and (max-width: 1599px) {
    html,body {
        font-size: 13px!important;
    }
}
@media all and (min-width: 1224px) and (max-width: 1439px) {
    html,body {
        font-size: 11px!important;
    }
}
@media all and (min-width: 1024px) and (max-width: 1223px) {
    html,body {
        font-size: 9px!important;
    }
}
</style>