<template>
	<section class="menu"> 
		<List class="list">
			<template #content>
				<ListItem v-for="item in menu" :key="item.id">
					<template #content>
						<img :src="item.img" alt="" class="item__img">
						<span class="item__title">{{ item.title }}</span>
					</template>
				</ListItem>
			</template>
		</List>
	</section>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';


export default {
	name: 'CartMenu',
	components: {
		List,
		ListItem
	},
	data() {
		return {
			menu: [
				{
					title: 'Engine parts',
					img: require('../assets/images/engine1.png')
				},
				{
					title: 'Automotive oils and technical fluids',
					img: require('../assets/images/oil1.png')
				},
				{
					title: 'Automotive filters',
					img: require('../assets/images/filters1.png')
				},

				{
					title: 'Suspension parts',
					img: require('../assets/images/amort1.png')
				},
				{
					title: 'Braking system',
					img: require('../assets/images/brks1.png')
				},
				{
					title: 'Batteries',
					img: require('../assets/images/akum1.png')
				},
			]
		}
	},
}
</script>

<style scoped>
.list {
	display: grid;
    gap: 0;
    grid-template-columns: repeat(3,1fr);
}

.item__img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.item__title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-orange-kz);
    background: #00000096;
    position: absolute;
    bottom: 0;
    width: 100%;
	min-height: 4.6875rem;
	font-size: 1.5rem;
	text-align: center;
}

@media all and (max-width: 1023px) {
	.list {
		grid-template-columns: repeat(2,1fr);
	}
}

@media all and (max-width: 500px) {
	.list {
		grid-template-columns: repeat(1,1fr);
	}
}
</style>