<template>
	<footer class="footer">
		<div class="footer__wrapper">
			<span class="footer__text">© 2022 ONLINEPARTS.KZ</span>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>

<style scoped>
.footer {
	display: flex;
    align-items: center;
    justify-content: center;
	
	background: #333;
	border-top: 1px solid #ddd;
	min-height: 6.5rem;
}

.footer__text {
	color: white;
	font-size: 1.25rem;
	font-weight: 200;
	letter-spacing: 0.03em;
}
</style>