<template>
	<div class="container">
		<div class="container__wrapper">
			<!-- <img src="@/assets/images/404.svg" alt="" class="img"> -->

			<span class="text">Wrong way...</span>

			<Button class="button" @click="this.$router.go(-1)">
				<template #text>
					<span class="button__text">Back</span>
				</template>
			</Button>
		</div>
	</div>	
</template>

<script>
import Button from '@/components/Ui/Button.vue';

export default {
	name: 'PageNotFound',
	components: {
		Button
	}
}
</script>

<style scoped>

.container {
	position: fixed;
    background: #f8f8f8;
    top: 0;
    z-index: 10000000000;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.container__wrapper {
	display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
	max-height: 70%;
}

.img {
	max-height: 100%;
}

.button {
	background: var(--color-blue1);
}
.button:hover {
	opacity: 1;
	background: var(--color-blue2);
}

.text {
	font-size: 1.875rem;
    font-weight: 600;
    color: var(--color-black1);
    letter-spacing: 0.03em;
	margin-bottom: 1.875rem;
}
</style>