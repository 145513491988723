<template>
	<div class="info">
		<div class="info__wrapper">

			<List class="list">
				<template #content>
					<ListItem class="item">
						<template #content>
							<span class="item__title"><a href="http://onlineparts.kz">ONLINEPARTS.KZ</a></span>
						</template>
					</ListItem>

					<ListItem class="item">
						<template #content>
							<span class="item__title item__title-font">BJB Distribution  Ltd Co</span>
						</template>
					</ListItem>

					<ListItem class="item">
						<template #content>
							<span class="item__title item__title-font">Sole trader Akimbaev N.</span>
						</template>
					</ListItem>

					<ListItem class="item">
						<template #content>
							<span class="item__title">Adress:</span>
							<div class="item__body">
								<span class="item__text">Rayimbek Ave 82, Almaty 050000, Kazakhstan</span>
							</div>
						</template>
					</ListItem>

					<ListItem class="item">
						<template #content>
							<span class="item__title">Telephone:</span>
							<div class="item__body">
								<div class="item__wrapper">
									<a :href="`tel:${+77272718541}`" class="item__text">+7 (727) 271-85-41</a>
									<a :href="`tel:${+77272731993}`" class="item__text">+7 (727) 273-19-93</a>
									<a :href="`tel:${+77272713125}`" class="item__text">+7 (727) 273-31-25</a>
								</div>
								<div class="item__wrapper">
									<a :href="`tel:${+77078697181}`" class="item__text">+7 (707) 869-71-81</a>
									<a :href="`tel:${+77078697182}`" class="item__text">+7 (707) 869-71-82</a>
								</div>
							</div>
						</template>
					</ListItem>

					<ListItem class="item">
						<template #content>
							<span class="item__title">Email:</span>
							<div class="item__body">
								<a :href="`mailto:${'offer@onlineparts.kz'}`" class="item__text">offer@onlineparts.kz</a>
							</div>
						</template>
					</ListItem>

				</template>
			</List>

		</div>
	</div>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';

export default {
	name: 'ContactsInfo', // BJB Distribution  Ltd Co |||| Sole trader Akimbaev N.
	components: {
		List,
		ListItem,
	},
}
</script>

<style scoped>
.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content__wrapper {
	display: flex;
    max-width: 101.0625rem;
    width: 100%;
	justify-content: space-between;

	padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}

.item__title {
	color: #333;
	font-weight: 600;
    font-size: 1.5rem;
	min-width: 9.375rem;
}
.item__text {
	color: #333;
	font-weight: 300;
    font-size: 1.25rem;
	transition-duration: 120ms;
	cursor: pointer;
}
.item__text:hover {
	opacity: 0.7;
}


.list {
    display: flex;
    flex-direction: column;
	gap: 3.125rem;
}

.item {
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
}

.item__wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
}

.item__body {
    width: 100%;
    display: flex;
	gap: 2.75rem;
}

.item__title-font {
	font-weight: 300;
	font-size: 1.25rem;
}

@media all and (max-width: 1023px) {
	.content__wrapper {
		width: auto;
	}

	.item {
		flex-direction: column;
		text-align: center;
	}

	.item__body {
		justify-content: center;
		flex-direction: column;
	}
}
</style>