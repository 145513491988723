export default {
	state: {
		menu: [
			{
				title: 'Products',
				link: '#products'
			},
			{
				title: 'About company',
				link: '#about'
			},
			{
				title: 'Contacts',
				link: '#contacts'
			},
		]
	},
	getters: {
		menu(state) {
			return state.menu;
		},
	},
	mutations: {
		setMenu(state, payload) {
			state.menu = payload;
		},
	},
	actions: {

	},
}
