<template>
	<ul class="list">
		<slot name=content></slot>
	</ul>
</template>

<script>
export default {
	name: 'List',
}
</script>

<style scoped>
.list {
	display: flex;
    gap: 1.25rem;
}
</style>