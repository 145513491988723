<template>
	<section class="advantages">
		<div class="advantages__wrapper">
			<BlockTitle>
				<template #title>Our advantages</template>
			</BlockTitle>

			<div class="content">
				<div class="content__wrapper">
					<List class="list">
						<template #content>
							<ListItem class="item" v-for="item in menu" :key="item.id">
								<template #content>
									<img :src="item.img" alt="">
									<p><b>{{ item.title }}</b> <br> {{ item.description }}</p>
								</template>
							</ListItem>
						</template>
					</List>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';
import BlockTitle from '@/components/BlockTitle.vue';

export default {
	name: 'Advantages',
	components: {
		List,
		ListItem,
		BlockTitle
	},
	data() {
		return {
			menu: [
				{
					title: 'Items available',
					description: 'The products shown on our website are always available in stock. The order will be shipped within a short time.',
					img: require('../assets/images/icons/product.svg')
				},
				{
					title: 'Original quality',
					description: 'We sell only original parts from proven manufacturers.',
					img: require('../assets/images/icons/like.svg')
				},
				{
					title: 'Conforms to OEM specifications',
					description: 'You can be sure that the part of the brand you need will definitely fit your car.',
					img: require('../assets/images/icons/star.svg')
				},
				{
					title: 'Competent sales assistants',
					description: 'Our employees are experts. They will help you to choose parts and consulate you professionally.',
					img: require('../assets/images/icons/people.svg')
				},
			]
		}
	},
}
</script>

<style scoped>
.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content__wrapper {
	display: flex;
    max-width: 101.0625rem;
    width: 100%;
	justify-content: space-between;

	padding-top: 7.5rem;
    padding-bottom: 7.5rem;

	margin-left: 1.25rem;
    margin-right: 1.25rem;
}


.list {
	display: grid;
    gap: 5.9375rem 6.25rem;
    grid-template-columns: repeat(2,1fr);
}


.item > p {
    font-size: 1.125rem;
	letter-spacing: 0.03em;
    color: #333;
    font-weight: 300;
    line-height: 1.6875rem;
}

.item {
    display: flex;
    gap: 1.25rem;
}

.item > img {
    max-width: 5.4375rem;
}

.item > p > b {
    font-size: 1.5rem;
    line-height: 3.25rem;
}

@media all and (max-width: 1023px) {
	.list {
		grid-template-columns: repeat(1,1fr);
	}
}
</style>