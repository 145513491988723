<template>
	<section class="about" name="about">
		<a name="about"/>
		<div class="about__wrapper">
			<BlockTitle>
				<template #title>About company</template>
			</BlockTitle>

			<div class="content">
				<div class="content__wrapper">
					<div class="text">
						<p>
							<b>ONLINE PARTS</b> – is a company founded in 2005. We sell automotive parts and automotive chemicals 
							from the world's leading manufacturers, wholesale and retail. Our range includes more than 6000 items 
							of certified quality goods only. 
						</p>
						<p>
							We are distributor of Japanese brands: TCL, Furukawa Battery, ADVICS, HKT, Denso, GMB, Koito, 
							SUN, Sankei, Bando, Koyo, Tokico, NSK.
						</p>
						<p>
							In our 17 year history, we have acquired high competence and extensive experience in our business. 
							We employ true professionals who not only understand the automotive industry, but also truly love what they do.
						</p>
					</div>

					<List class="list">
						<template #content>
							<ListItem class="item">
								<template #content>
									<span class="item__title">
										Our mission
									</span>
									<div class="item__body">
										<span class="item__text">
											To be a reliable supplier of high quality automotive parts and automotive 
											chemicals from proven manufacturers in Kazakhstan.
										</span>
									</div>
								</template>
							</ListItem>

							<ListItem class="item">
								<template #content>
									<span class="item__title">
										Our priorities
									</span>
									<div class="item__body item__body-column">
										<span class="item__text">We provide high quality goods;</span>
										<span class="item__text">We focus on the clients and their desires;</span>
										<span class="item__text">We are developing and growing professionally.</span>
									</div>
								</template>
							</ListItem>
						</template>
					</List>
				</div>
			</div>

			<div class="content">
				<div class="content__wrapper">
					<AboutInfo></AboutInfo>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';
import BlockTitle from '@/components/BlockTitle.vue';
import AboutInfo from '@/components/AboutInfo.vue';

export default {
	name: 'About',
	components: {
		List,
		ListItem,
		BlockTitle,
		AboutInfo
	},
}
</script>

<style scoped>
.content {
    display: flex;
    align-items: center;
    justify-content: center;
	border-bottom: 4px solid var(--color-blue-kz);
}
.content:last-child {
    border-bottom: 0;
}
.content__wrapper {
	display: flex;
    max-width: 101.0625rem;
    width: 100%;
	justify-content: space-between;

	padding-top: 7.5rem;
    padding-bottom: 7.5rem;

	margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.text {
    max-width: 40.625rem;
    display: block;
}

.text > p {
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.03em;
    line-height: 1.5em;
	margin-top: 0;
}


.list {
	display: flex;
	flex-direction: column;
	gap: 6.625rem;
	align-items: flex-end;
}

.item {
	display: flex;
    gap: 1.9375rem;
}
.item__title {
	color: var(--color-orange-kz);
}
.item__text {
	color: #333;
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 1.6875rem;
}

.item__body {
    max-width: 44.25rem;
	width: 44.25rem;
    border: 4px solid var(--color-blue-kz);
    padding: 0.9375rem;
    border-left: 0;
    border-right: 0;
    padding-right: 0;
    padding-left: 0;
}
.item__body-column {
	display: flex;
	flex-direction: column;
}

@media all and (max-width: 1023px) {
	.content__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 40px;
	}

	.list {
		gap: 4.625rem;
	}

	.item {
		width: 100%;
		flex-direction: column;
		text-align: center;
	}

	.item__body {
		width: auto;
	}
}
</style>