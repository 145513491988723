<template>
	<div class="logo">
		<img src="@/assets/logo.svg" alt="" class="img">
	</div>
</template>

<script>
export default {
	name: 'Logo'
}
</script>

<style scoped>
.logo {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 15.625rem;
}
.img {
	max-width: 100%;
}

@media all and (max-width: 390px) {
	.logo {
		width: 11.625rem;
	}
}
</style>