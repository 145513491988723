<template>
	<li class="item">
		<slot name="content"></slot>
	</li>
</template>

<script>
export default {
	name: 'ListItem'
}
</script>

<style scoped>
.item {
	font-size: 1.375rem;
    font-weight: 400;
    letter-spacing: 0.06em;
	color: white;
	transition-duration: 120ms;
	position: relative;
}
</style>