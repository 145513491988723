<template>
	<div class="menu">
		<div class="menu__wrapper">
			<List class="list">
				<template #content>

					<ListItem v-for="item in menu" :key="item.id" class="item">
						<template #content>

							<Button class="button" @click="isActive = !isActive">
								<template #text>
									<a :href="item.link" class="button__text">{{ item.title }}</a>
								</template>
							</Button>

						</template>
					</ListItem>

				</template>
			</List>
		</div>
	</div>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';
import Button from '@/components/Ui/Button.vue';

export default {
	name: 'Menu',
	components: {
		List,
		ListItem,
		Button
	},
	computed: {
		menu: {
			get() {
				return this.$store.getters.menu;
			}
		}
	}
}
</script>

<style scoped>
.list {
	gap: 3.4375rem;
}

.button {
    padding: 0;
    margin: 0;
    background: none;
}

.button__text {
	color: #333;
	font-size: 1.5rem;
	font-weight: 300;
}
</style>