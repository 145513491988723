export default {
	state: {
		languages: [
			{
				name: 'Ru',
				title: 'Русский язык',
				img: require('@/assets/images/language/Rus.svg'),
				link: 'http://onlineparts.kz'
			},
			/*{
				name: 'Kz',
				title: 'Казахский язык',
				img: require('@/assets/images/language/Kz.svg'),
				link: 'http://onlineparts.kz'
			}, */
			{
				name: 'Eng',
				title: 'Английский язык',
				img: require('@/assets/images/language/Eng.svg'),
				link: null
			}
		],
		selectLang: undefined,
	},
	getters: {
		languages(state) {
			return state.languages;
		},
		selectLang(state) {
			return state.selectLang;
		}
	},
	mutations: {
		setSelectLang(state, payload) {
			state.selectLang = payload;
		},
	},
	actions: {

	},
}
