<template>
	<div class="menu">
		<div class="menu__wrapper">

			<Button class="button" @click="isActive = !isActive">
				<template #text>
					<i class="fas fa-bars button__icon"></i>
				</template>
			</Button>

			<Transition name="popup-anim">
			<div class="modal"  v-if="isActive">
				<div class="modal__wrapper">
					<Button class="button-cross" @click="isActive = !isActive">
						<template #text>
							<i class="fas fa-times button__icon"></i>
						</template>
					</Button>
					
					<List class="list">
						<template #content>
							<ListItem v-for="item in menu" :key="item.id" class="item">
								<template #content>

									<Button class="button" @click="isActive = !isActive">
										<template #text>
											<a :href="item.link" class="button__text">{{ item.title }}</a>
										</template>
									</Button>
									
								</template>
							</ListItem>
								
							<Language></Language>
						</template>
					</List>
				</div>
			</div>
			</Transition>

		</div>
	</div>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';
import Button from '@/components/Ui/Button.vue';
import Language from '@/components/Language.vue';

export default {
	name: 'MenuMobile',
	components: {
		List,
		ListItem,
		Button,
		Language
	},
	data() {
		return {
			isActive: false,
		}
	},
	computed: {
		menu: {
			get() {
				return this.$store.getters.menu;
			}
		}
	}
}
</script>

<style scoped>
.modal {
	position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal__wrapper {
	position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
	width: 100%;
    height: 100%;
}

.item {
	align-items: center;
    justify-content: center;
    display: flex;
}

.button {
    padding: 0;
    margin: 0;
    background: none;
}

.button-cross {
   position: absolute;
    top: 2.5rem;
    right: 1.625rem;
}

.button__icon {
    font-size: 2.125rem;
    color: #333;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    text-align: center;
}

.button__text {
	color: #333;
	font-size: 1.5rem;
	font-weight: 300;
	text-align: center;
}
</style>