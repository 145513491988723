<template>
	<main class="main">
		<div class="main__wrapper">
			<router-view></router-view>
		</div>
	</main>
</template>

<script>
export default {
	name: 'Main'
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    transition-duration: var(--animationTime_400);
	background: #f8f8f8;
}

.main__wrapper {
    display: flex;
    flex-direction: column;
}

.main__header {
    display: flex;
}

.main__body {
    display: flex;
}
</style>