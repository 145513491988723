<template>
	<section class="brands">
		<div class="brands__wrapper">
			<BlockTitle>
				<template #title>Our brands</template>
			</BlockTitle>

			<div class="content">
				<div class="content__wrapper">
					<div class="title">
						<span class="title__text">We sell only high-quality spare parts from proven manufacturers. These are long established world famous brands.</span>
					</div>
					
					<Carousel class="carousel" :wrapAround="true" :itemsToShow="3" :itemsToScroll="1" :autoplay="3000" :transition="1000" :breakpoints="breakpoints">
						<template #slides>
							<Slide v-for="brand in brands" :key="brand" class="slide">
								<img :src="brand.img" alt="" class="slide__img" :class="[{ 'slide__img-scale':brand.name == 'NKT' }, { 'slide__img-scaleUp':brand.name == 'ADVICS' }]">
							</Slide>
						</template>
					</Carousel>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BlockTitle from '@/components/BlockTitle.vue';

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
	name: 'Brands',
	components: {
		BlockTitle,

		Carousel,
		Slide,
		Navigation
	},
	data() {
		return {
			brands: [
				{
					name: 'TANIKAWAYUKA KOGYO CO.,LTD.',
					country: 'Japan',
					img: require('../assets/images/brands/tcl.png'),
					link: 'https://tanikawayuka.co.jp/',
					public: true
				},
				{
					name: 'The Furukawa Battery Co., Ltd.',
					country: 'Japan',
					img: require('../assets/images/brands/fb.png'),
					link: 'https://www.furukawadenchi.co.jp/',
					public: true
				},
				{
					name: 'ADVICS',
					country: 'Japan',
					img: require('../assets/images/brands/advics.png'),
					link: 'http://www.advicsaftermarket.co.jp/',
					public: true
				},
				{
					name: 'NKT',
					country: 'Japan',
					img: require('../assets/images/brands/hkt.png'),
					link: 'https://www.hkt-jp.com/',
					public: true
				},
				{
					name: 'Denso',
					country: 'Japan',
					img: require('../assets/images/brands/denso.png'),
					link: '',
					public: true
				},
				{
					name: 'GMB CORPORATION',
					country: 'Japan',
					img: require('../assets/images/brands/gmb.png'),
					link: 'https://www.gmb.jp/',
					public: true
				},
				{
					name: 'KOITO MANUFACTURING CO., LTD.',
					country: 'Japan',
					img: require('../assets/images/brands/koito.png'),
					link: 'https://www.koito.co.jp',
					public: true
				},
				{
					name: 'USHIO INDUSTRIES, LTD.',
					country: 'Japan',
					img: require('../assets/images/brands/sun.png'),
					link: 'https://www.ushio-ind.co.jp/',
					public: true
				},
				{
					name: 'Sankei Industry Co., Ltd.',
					country: 'Japan',
					img: require('../assets/images/brands/555.png'),
					link: 'http://www.sankei-555.co.jp/',
					public: true
				},
				{
					name: 'Bando Chemical Industries, Ltd.',
					country: 'Japan',
					img: require('../assets/images/brands/bando.png'),
					link: 'https://www.bandogrp.com/index.html',
					public: true
				},
				{
					name: 'KOYO CO., LTD.',
					country: 'Japan',
					img: require('../assets/images/brands/koyo.png'),
					link: '',
					public: true
				},
				{
					name: 'TOKICO',
					country: 'Japan',
					img: require('../assets/images/brands/tokico.png'),
					link: '',
					public: true
				},
				{
					name: 'NSK',
					country: 'Japan',
					img: require('../assets/images/brands/nsk.png'),
					link: '',
					public: true
				},
			],
			breakpoints: {
			    1024: {
				itemsToShow: 10,
				snapAlign: "start"
			    }
			}
		}
	},
}
</script>

<style scoped>
.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content__wrapper {
	display: flex;
	flex-direction: column;
    max-width: 101.0625rem;
    width: 100%;
	gap: 3.75rem;
	justify-content: space-between;

	padding-top: 7.5rem;
    padding-bottom: 7.5rem;

	margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.title__text {
    font-size: 1.125rem;
    font-weight: 300;
	letter-spacing: 0.03em;
	line-height: 1.6875rem;
}

.carousel {
	display: flex;
	gap: 1.25rem;
}

.slide__img {
	max-width: 8.125rem;
	width: 8.125rem;
	user-select: none;
}

.slide__img-scale {
	transform: scale(0.7);
}
.slide__img-scaleUp {
	transform: scale(1.5);
}

@media all and (max-width: 1023px) {
	.title {
		text-align: center;
	}
}
</style>