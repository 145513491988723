export default {
	state: {
		isMobile: false,
	},
	getters: {
		isMobile(state) {
			return state.isMobile;
		}
	},
	mutations: {
		setIsMobile(state, payload) {
			state.isMobile = payload;
		}
	},
	actions: {

	},
}
