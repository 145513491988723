import { createApp } from "vue";
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import moment from 'moment';
import vClickOutside from "click-outside-vue3/src";

const app = createApp(App)
.use(store)
.use(router)
.use(vClickOutside);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = moment;

app.mount('#app');
