import { createStore } from 'vuex'
import Mobile from './modules/mobile';
import Language from './modules/language';
import Menu from './modules/menu';

export default createStore({
	modules: {
		Mobile,
		Language,
		Menu
	}
})
