<template>
	<button class="button">
		<div class="button__icon">
			<slot name="icon-before"></slot>
		</div>

		<span class="button__text">
			<slot name="text"></slot>
		</span>

		<div class="button__icon">
			<slot name="icon-after"></slot>
		</div>
	</button>
</template>

<script>
export default {
	name: 'Button'
}
</script>

<style scoped>
.button {
	display: flex;
    align-items: center;
    justify-content: center;

	gap: 0.5rem;

    background-color: #e64769;
    border: 0;
    border-radius: 1.875rem;

    padding: 1rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;

    transition-duration: 140ms;
	user-select: none;
}

.button__text {
	font-family: 'Nunito';
	font-size: 1rem;
	font-weight: 700;
	color: white;
	letter-spacing: 0.03em;
}

.button__icon {
	display: contents;
}

.button:hover {
	transform: translate3d(0,0,0) scale(1.08);
    opacity: 0.8; 
}  

.button-active {
	opacity: 0.3;
	pointer-events: none;
}
</style>