<template>
	<div class="language">
		<List class="list">
			<template #content>
				<ListItem class="item" v-for="lang in languages" :key="lang.id">
					<template #content>

						<Button class="button" @click="selecLang = lang">
							<template #text>
								<img :src="lang.img" alt="" class="img" :class="{ 'img-opacity':lang.name != selecLang.name }">
							</template>
						</Button>
						
					</template>
				</ListItem>
			</template>
		</List>
	</div>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';
import Button from '@/components/Ui/Button.vue';

export default {
	name: 'Language',
	components: {
		List,
		ListItem,
		Button
	},
	computed: {
		languages: {
			get() {
				return this.$store.getters.languages;
			}
		},
		selecLang: {
			get() {
				return this.$store.getters.selectLang;
			},
			set(value) {
				if(value.link) {
				    window.location.href = value.link;
				}
				if(value.name == this.selecLang.name) {
					return;
				}
				this.$store.commit('setSelectLang', value);
			}
		}
	},
}
</script>

<style scoped>
.list {
	justify-content: center;
	gap: 2.375rem;
}

.item {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
}

.img {
	max-width: 100%;
}
.img-opacity {
	opacity: 0.2;
}

.button {
	padding: 0;
    margin: 0;
    background: none;
}
</style>