<template>
	<section class="geography">
		<div class="geography__wrapper">
			<BlockTitle>
				<template #title>Geographical presence</template>
			</BlockTitle>

			<div class="content">
				<div class="content__wrapper">
					<Map></Map>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BlockTitle from '@/components/BlockTitle.vue';
import Map from '@/components/Map.vue';

export default {
	name: 'Geography',
	components: {
		BlockTitle,
		Map
	}
}
</script>

<style scoped>
.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content__wrapper {
	display: flex;
    max-width: 101.0625rem;
    width: 100%;
	justify-content: center;

	padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}
</style>