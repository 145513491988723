<template>
	<header class="header">
		<div class="header__wrapper">
			<Logo></Logo>

			<MenuMobile v-if="isMobile"></MenuMobile>
			<Menu v-else></Menu>
			
			<Language v-if="!isMobile"></Language>
		</div>
	</header>
</template>

<script>
import Menu from '@/components/Menu.vue';
import MenuMobile from '@/components/MenuMobile.vue';
import Logo from '@/components/Logo.vue';
import Language from '@/components/Language.vue';

export default {
	name: 'Header',
	components: {
		Menu,
		MenuMobile,
		Logo,
		Language
	},
	computed: {
		isMobile: {
			get() {
				return this.$store.getters.isMobile;
			}
		}
	},
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
	align-items: center;
}

.header__wrapper {
    display: flex;
    align-items: center;
    min-height: 7.0625rem;
    max-width: 101.0625rem;
    width: 100%;
    justify-content: space-between;

	padding-left: 1.25rem;
    padding-right: 1.25rem;
    box-sizing: border-box;
}
</style>