<template>
	<section class="contacts">
		<a name="contacts"/>
		<div class="contacts__wrapper">
			<BlockTitle>
				<template #title>Contacts</template>
			</BlockTitle>

			<div class="content">
				<div class="content__wrapper">
					<ContactsInfo></ContactsInfo>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BlockTitle from '@/components/BlockTitle.vue';
import ContactsInfo from '@/components/ContactsInfo.vue';

export default {
	name: 'Contacts',
	components: {
		BlockTitle,
		ContactsInfo
	}
}
</script>

<style scoped>
.content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content__wrapper {
	display: flex;
    max-width: 101.0625rem;
    width: 100%;
	justify-content: space-between;

	padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}

@media all and (max-width: 1023px) {
	.content__wrapper {
		justify-content: center;
	}
}
</style>