<template>
	<div class="title">
		<div class="title__wrapper">
			<span class="title__text">
				<slot name="title">Заголовок</slot>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlockTitle'
}
</script>

<style scoped>
.title {
    background: var(--color-blue-kz);
    min-height: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title__wrapper {
    display: flex;
    width: 100%;
    max-width: 117.4375rem;
	margin-left: 3.25rem;
    margin-right: 1.25rem;
}

.title__text {
    font-size: 2.125rem;
    font-weight: 900;
    color: #fff;
    letter-spacing: 0.03em;
    font-style: italic;
}
</style>