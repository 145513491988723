<template>
	<List class="list">
		<template #content>
			<ListItem class="item">
				<template #content>
					<span class="item__title">17 <br> <b>years</b></span>
					<span class="item__text">On the automotive parts market</span>
				</template>
			</ListItem>
			<ListItem class="item">
				<template #content>
					<span class="item__title"><b>More than</b> <br> 1 000</span>
					<span class="item__text">Unique customers per month</span>
				</template>
			</ListItem>
			<ListItem class="item">
				<template #content>
					<span class="item__title"><b>More than</b> <br>100</span>
					<span class="item__text">Famous manufacturers</span>
				</template>
			</ListItem>

			<ListItem class="item">
				<template #content>
					<span class="item__title"><b>More than</b> <br> 15 000</span>
					<span class="item__text">High quality goods</span>
				</template>
			</ListItem>
		</template>
	</List>
</template>

<script>
import List from '@/components/Ui/List/List.vue';
import ListItem from '@/components/Ui/List/ListItem.vue';

export default {
	name: 'AboutInfo',
	components: {
		List,
		ListItem,
	},
}
</script>

<style scoped>
.list {
    display: grid!important;
    grid-template-columns: repeat(4,1fr);
	gap: 3.75rem!important;
}

.item__text {
	display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.6875rem;
    color: #333;
    font-weight: 300;
    font-size: 1.5rem;
}

.item__title {
    color: var(--color-orange-kz);
    white-space: nowrap;
    text-align: center;
	font-size: 3.125rem;
	line-height: 1em;
}

.item__title > b {
	font-size: 2.1875rem;
    color: #333;
}

.item {
    display: flex;
	border-right: 4px solid var(--color-blue-kz);
	padding-right: 1.5625rem;
}
.item:last-child {
    padding-right: 0;
    border-right: 0;
}


@media all and (max-width: 1023px) {
	.list {
		display: flex!important;
		gap: 2.625rem 0!important;
		
	}
	.item {
		flex-direction: column;
		border-right: 0;
		padding-right: 0;
		border-bottom: 4px solid var(--color-blue-kz);
		padding-bottom: 3.5625rem;
		width: 100%;
	}
	.item:last-child {
		border-bottom: 0;
		padding-bottom: 0;
	}
}
</style>