<template>
	<div>
		<CartMenu></CartMenu>
		<Brands></Brands>
		<About></About>
		<Advantages></Advantages>
		<Geography></Geography>
		<Contacts></Contacts>
	</div>
</template>

<script>
import CartMenu from '@/components/CartMenu.vue';
import Brands from '@/components/Brands.vue';
import About from '@/components/About.vue';
import Advantages from '@/components/Advantages.vue';
import Geography from '@/components/Geography.vue';
import Contacts from '@/components/Contacts.vue';

export default {
	name: 'HomeViews',
	components: {
		CartMenu,
		Brands,
		About,
		Advantages,
		Geography,
		Contacts
	},
}
</script>
